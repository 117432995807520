* {
    box-shadow: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behaviour: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;